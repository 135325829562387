import styled from 'styled-components';
import { theme } from './icons.theme';

import { ReactComponent as AddSvg } from 'assets/icons/add.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg';
import { ReactComponent as CheckSvg } from 'assets/icons/check.svg';
import { ReactComponent as CircleArrowSvg } from 'assets/icons/circle-arrow.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import { ReactComponent as CloseSmallSvg } from 'assets/icons/close-small.svg';
import { ReactComponent as CornerDownSvg } from 'assets/icons/corner-down.svg';
import { ReactComponent as DeleteSvg } from 'assets/icons/delete.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as DotsSvg } from 'assets/icons/dots.svg';
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg';
import { ReactComponent as ErrorSvg } from 'assets/icons/error.svg';
import { ReactComponent as GoArrowSvg } from 'assets/icons/go-arrow.svg';
import { ReactComponent as ImageErrorSvg } from 'assets/icons/image-error.svg';
import { ReactComponent as LocationSvg } from 'assets/icons/location.svg';
import { ReactComponent as LogoSvg } from 'assets/icons/logo.svg';
import { ReactComponent as MagnifySvg } from 'assets/icons/magnify.svg';
import { ReactComponent as MessageSvg } from 'assets/icons/message.svg';
import { ReactComponent as NoPhotoSvg } from 'assets/icons/no-photo.svg';
import { ReactComponent as PenSvg } from 'assets/icons/pen.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/plus.svg';
import { ReactComponent as SearchSvg } from 'assets/icons/search.svg';
import { ReactComponent as SortSvg } from 'assets/icons/sort.svg';
import { ReactComponent as StarSvg } from 'assets/icons/star.svg';
import { ReactComponent as DropdownSvg } from 'assets/icons/dropdown.svg';
import { ReactComponent as StarBigSvg } from 'assets/icons/star-big.svg';
import { ReactComponent as SuccessSvg } from 'assets/icons/success.svg';
import { ReactComponent as SuccessSmallSvg } from 'assets/icons/success-small.svg';
import { ReactComponent as TriangleSvg } from 'assets/icons/triangle.svg';
import { ReactComponent as WarnQuestionSvg } from 'assets/icons/warn-question.svg';
import { ReactComponent as NotAvailableSvg } from 'assets/icons/not_dates.svg';
import { ReactComponent as BlueCopyIconSvg } from 'assets/icons/blue_copy_icon.svg';
import { ReactComponent as GreyCopyIconSvg } from 'assets/icons/grey_copy_icon.svg';
import { ReactComponent as UserBlueSvg } from 'assets/icons/user_blue.svg';
import { ReactComponent as UserGreySvg } from 'assets/icons/user_grey.svg';
import { ReactComponent as BudgetSvg } from 'assets/icons/budget_icon.svg';
import { ReactComponent as HeartSvg } from 'assets/icons/heart.svg';
import { ReactComponent as StopSalesSvg } from 'assets/icons/stop_sales.svg';
import { ReactComponent as PlusPageSvg } from 'assets/icons/plusPage.svg';
import { ReactComponent as PlusPageBlueSvg } from 'assets/icons/plusPageBlue.svg';
import { ReactComponent as BedSvg } from 'assets/icons/bed.svg';

import { ReactComponent as DepartureArrowIconSvg } from 'assets/icons/departure_arrow.svg';
import { ReactComponent as ArriveArrowIconSvg } from 'assets/icons/arrive_arrow.svg';
import { ReactComponent as DepartureArriveArrowIconSvg } from 'assets/icons/dep_arr_arrow.svg';
import { ReactComponent as YellowAttentionIconSvg } from 'assets/icons/yellow_attantion.svg';
import { ReactComponent as RedAttentionIconSvg } from 'assets/icons/red_attention.svg';
import { ReactComponent as QuestionIconSvg } from 'assets/icons/question.svg';

export const DepartureArriveArrowIcon = styled(DepartureArriveArrowIconSvg)`${theme}`;
export const ArriveArrowIcon = styled(ArriveArrowIconSvg)`${theme}`;
export const DepartureArrowIcon = styled(DepartureArrowIconSvg)`${theme}`;

export const Add = styled(AddSvg)`${theme}`;
export const PlusPageBlueIcon = styled(PlusPageBlueSvg)`${theme}`;
export const ArrowLeft = styled(ArrowLeftSvg)`${theme}`;
export const Calendar = styled(CalendarSvg)`${theme}`;
export const Check = styled(CheckSvg)`${theme}`;
export const CircleArrow = styled(CircleArrowSvg)`${theme}`;
export const Close = styled(CloseSvg)`${theme}`;
export const CloseSmall = styled(CloseSmallSvg)`${theme}`;
export const CornerDown = styled(CornerDownSvg)`${theme}`;
export const Delete = styled(DeleteSvg)`${theme}`;
export const Document = styled(DocumentSvg)`${theme}`;
export const Dots = styled(DotsSvg)`${theme}`;
export const Heart = styled(HeartSvg)`${theme}`;
export const Edit = styled(EditSvg)`${theme}`;
export const Error = styled(ErrorSvg)`${theme}`;
export const GoArrow = styled(GoArrowSvg)`${theme}`;
export const Budget = styled(BudgetSvg)`${theme}`;
export const ImageError = styled(ImageErrorSvg)`${theme}`;
export const Location = styled(LocationSvg)`${theme}`;
export const Dropdown = styled(DropdownSvg)`${theme}`;
export const Logo = styled(LogoSvg)`${theme}`;
export const Magnify = styled(MagnifySvg)`${theme}`;
export const Message = styled(MessageSvg)`${theme}`;
export const NoPhoto = styled(NoPhotoSvg)`${theme}`;
export const Pen = styled(PenSvg)`${theme}`;
export const Plus = styled(PlusSvg)`${theme}`;
export const Search = styled(SearchSvg)`${theme}`;
export const Sort = styled(SortSvg)`${theme}`;
export const Star = styled(StarSvg)`${theme}`;
export const StarBig = styled(StarBigSvg)`${theme}`;
export const Success = styled(SuccessSvg)`${theme}`;
export const SuccessSmall = styled(SuccessSmallSvg)`${theme}`;
export const Triangle = styled(TriangleSvg)`${theme}`;
export const WarnQuestion = styled(WarnQuestionSvg)`${theme}`;
export const NotAvailable = styled(NotAvailableSvg)`${theme}`;
export const BlueCopyIcon = styled(BlueCopyIconSvg)`${theme}`;
export const GreyCopyIcon = styled(GreyCopyIconSvg)`${theme}`;
export const UserBlueIcon = styled(UserBlueSvg)`${theme}`;
export const UserGreyIcon = styled(UserGreySvg)`${theme}`;
export const StopSalesIcon = styled(StopSalesSvg)`${theme}`;
export const PlusPageIcon = styled(PlusPageSvg)`${theme}`;
export const BedIcon = styled(BedSvg)`${theme}`;
export const YellowAttentionIcon = styled(YellowAttentionIconSvg)`${theme}`;
export const RedAttentionIcon = styled(RedAttentionIconSvg)`${theme}`;
export const QuestionIcon = styled(QuestionIconSvg)`${theme}`;